@tailwind base;
@tailwind components;
@tailwind utilities;

/* 
	high quality brain rot 
	fix for text shifting down on pdf export 
	https://github.com/niklasvh/html2canvas/issues/2775 
*/
@layer base {
	img {
		@apply inline-block;
	}
}

* {
	margin: 0;
	padding: 0;
}
*,
*:before,
*:after {
	box-sizing: border-box;
}

h1,
h2,
h3,
h4,
h5 {
	@apply font-signifier;
}

:root {
	--box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
	--border: 1px solid hsl(0, 0%, 92%);
	--border2: 1px solid hsl(0, 0%, 80%);

	--header-bg-color: #fff;
	--main-bg-color: hsl(0, 0%, 97%);

	--primary-color: hsl(24, 100%, 61%);
	--error-color: #ff4242;
	--row-highlight: hsl(24, 100%, 95%);
	--row-border-highlight: #f5a32e77;

	--success-light: hsl(24, 100%, 95%);
	--success: hsl(24, 100%, 80%);
	--success-dark: #000;
	--warning-light: hsl(62, 78%, 95%);
	--warning: hsl(62, 78%, 64%);
	--warning-dark: hsl(62, 78%, 50%);
	--danger-light: #ffdfdf;
	--danger: #ff4242;
	--danger-dark: #832121;
	--info: hsl(24, 100%, 80%);
	--info-dark: #000;

	--yellow: #fffb61;
	--light-grey: #e7e6e6;
	--dark-grey: #3f3f3f;
	--light-green: #c4eee0;
	--dark-green: #73d4ab;
	--light-blue: #2c99f2;
	--dark-blue: #00256d;
	--light-red: #ff7676;

	--border-radius: 2px;

	/* Carrot colors */

	--black: #000;
	--black-lighter: hsl(0, 0%, 15%);
	--black-light: hsl(0, 0%, 30%);
	--grey: hsl(0, 24%, 96%);
	--grey-lighter: hsl(0, 24%, 97%);
	--grey-darker: hsl(0, 4%, 80%);

	--carrot: hsl(24, 100%, 61%);
	--carrot-darker: hsl(24, 100%, 80%);
	--carrot-dark: hsl(24, 100%, 70%);
	--carrot-lighter: hsl(24, 100%, 95%);
	--carrot-light: hsl(24, 100%, 97%);

	--beige: hsl(26, 42%, 90%);
	--beige-darker: hsl(26, 42%, 85%);
	--beige-lighter: hsl(26, 42%, 95%);
	--beige-dark: hsl(26, 42%, 70%);

	--lime: hsl(62, 78%, 64%);
	--lime-darker: hsl(62, 78%, 50%);
	--lime-lighter: hsl(62, 78%, 95%);
	--lime-light: hsl(62, 78%, 98%);

	--fucales: hsl(60, 32%, 34%);
	--fucales-lighter: hsl(60, 32%, 40%);
	--fucales-light: hsl(60, 32%, 50%);

	--forest: #87874e;

	--lime-beige: linear-gradient(90deg, var(--lime-lighter) 0%, var(--beige) 100%);

	--lime-1: var(--lime); /* hsl(62, 78%, 64%); */
	--lime-2: hsl(62, 78%, 55%);
	--lime-3: hsl(62, 78%, 45%);
	--lime-4: hsl(62, 78%, 70%);
	--lime-5: hsl(62, 78%, 75%);

	--fucales-1: var(--fucales); /* hsl(60, 32%, 34%) */
	--fucales-2: hsl(60, 32%, 40%);
	--fucales-3: hsl(60, 32%, 45%);
	--fucales-4: hsl(60, 32%, 50%);
	--fucales-5: hsl(60, 32%, 55%);
	--fucales-6: hsl(60, 32%, 60%);
	--fucales-7: hsl(60, 32%, 65%);

	--carrot-1: hsl(24, 100%, 40%);
	--carrot-2: hsl(24, 100%, 45%);
	--carrot-3: hsl(24, 100%, 50%);
	--carrot-4: var(--carrot); /* hsl(24, 100%, 61%); */
	--carrot-5: hsl(24, 100%, 66%);
	--carrot-6: hsl(24, 100%, 70%);
	--carrot-7: hsl(24, 100%, 75%);

	--beige-1: var(--beige); /* hsl(26, 42%, 90%); */
	--beige-2: hsl(26, 42%, 80%);
	--beige-3: hsl(26, 42%, 75%);
	--beige-4: hsl(26, 42%, 70%);
	--beige-5: hsl(26, 42%, 65%);
	--beige-6: hsl(26, 42%, 60%);
	--beige-7: hsl(26, 42%, 55%);
	--beige-8: hsl(26, 42%, 50%);

	--black-1: hsl(0, 0%, 35%);
	--black-2: hsl(0, 0%, 40%);
	--black-3: hsl(0, 0%, 45%);
	--black-4: hsl(0, 0%, 50%);
	--black-5: hsl(0, 0%, 55%);
	--black-6: hsl(0, 0%, 60%);

	--lightgrey-1: var(--grey); /* hsl(0, 24%, 96%) */
	--lightgrey-2: hsl(0, 24%, 90%);
	--lightgrey-3: hsl(0, 24%, 85%);
	--lightgrey-4: hsl(0, 24%, 80%);
	--lightgrey-5: hsl(0, 24%, 75%);
	--lightgrey-6: hsl(0, 24%, 70%);

	--font-family-primary: "Signifier", sans-serif;
	--font-family-secondary: "DM Sans", sans-serif;

	/* Globals */
	--button-color: var(--carrot-darker);
	--button-hover-color: var(--carrot-dark);
	--button-text-color: var(--black);

	--sub-button-color: var(--fucales-light);
	--sub-button-hover-color: var(--fucales-lighter);
	--sub-button-text-color: var(--black);

	--link-text-color: var(--fucales);
	--link-admin-text-color: var(--carrot);

	--font-label: bold 1rem "DM Sans";
	--font-table-header: bold 0.9rem "DM Sans";
	--color-label: var(--black);

	--icon-color: var(--fucales);

	--tutorial-fill-color: var(--fucales);

	--main-error-message-color: var(--black);
}

/* Scrollbar */
::-webkit-scrollbar {
	width: 8px;
	height: 8px;
}

::-webkit-scrollbar-thumb {
	min-height: 30px;
	min-width: 30px;
	background-color: #babac0;
}

::-webkit-scrollbar-button {
	display: none;
}

html {
	font-size: 16px;
}

body {
	margin: 0;
	padding: 0;
	font-family: "DM Sans", sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

ul {
	list-style: none;
}

a {
	text-decoration: none;
	color: var(--black);
}

input {
	border: var(--border);
	border-radius: var(--border-radius);
}

input,
*:focus {
	outline: none;
}

input[placeholder] {
	text-overflow: ellipsis;
}

td {
	text-overflow: ellipsis;
	white-space: nowrap;
}

.flex-row {
	display: flex;
	flex-direction: row;
}

.flex-col {
	display: flex;
	flex-direction: column;
}

.flex-center-center {
	align-items: center;
	justify-content: center;
}

.flex-left-center {
	align-items: left;
	justify-content: center;
}

.flex-center-left {
	align-items: center;
	justify-content: left;
}

.flex-right-center {
	align-items: right;
	justify-content: center;
}

.flex-center-right {
	align-items: center;
	justify-content: right;
}

.col-gap-1 {
	column-gap: 1rem;
}

.row-gap-1 {
	row-gap: 1rem;
}

.h100 {
	height: 100%;
}

.w100 {
	width: 100%;
}

.p1 {
	padding: 1rem;
}

/* button {
	font-family: var(--font-family-secondary);
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	font-size: 0.9rem;
	font-weight: 600;
	padding: 0.5rem 1rem;
	color: var(--black);
	border: 0;
	border-radius: 4px;
	cursor: pointer;
	background-color: var(--carrot-darker);
	transition: background-color 150ms ease;
}
button:hover {
	background-color: var(--carrot-darker);
}
button:active {
	background-color: var(--danger-dark);
}
button:disabled {
	opacity: 0.5;
} */

.font-primary {
	font-family: var(--font-family-primary);
}

.font-secondary {
	font-family: var(--font-family-secondary);
}

/* #[0-9|a-f]{3,8}|var\(--.*\)|rgb(?:a)\(.*\)|hsl\(.*\) */
/* #[a-f0-9]{6,8} */

/* DRAWER */

svg .map-point {
	fill-opacity: 1;
	fill: var(--primary-color);
	stroke: none;
	stroke-width: 2px;
}

svg .map-backup-point {
	fill-opacity: 1;
	fill: var(--fucales);
	stroke: none;
	stroke-width: 2px;
}

.popup {
	position: relative;
}

.popup > .point-id {
	position: absolute;
	top: 1px;
	right: 5px;
}

main .popup > h1 {
	font-size: 0.9rem;
}

main .popup > span {
	display: block;
}

main .popup > a {
	display: block;
	padding-top: 1rem;
	color: var(--primary-color);
	font-weight: bolder;
}

/* /DRAWER */

.ReactVirtualized__Grid__innerScrollContainer,
.ReactVirtualized__Table__row,
.ReactVirtualized__Table__rowColumn {
	overflow: visible !important;
}
